// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aviso-legal-js": () => import("./../../../src/pages/aviso-legal.js" /* webpackChunkName: "component---src-pages-aviso-legal-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-jardin-hidalgo-js": () => import("./../../../src/pages/jardin-hidalgo.js" /* webpackChunkName: "component---src-pages-jardin-hidalgo-js" */),
  "component---src-pages-maestro-bosco-js": () => import("./../../../src/pages/maestro-bosco.js" /* webpackChunkName: "component---src-pages-maestro-bosco-js" */),
  "component---src-pages-pau-2-js": () => import("./../../../src/pages/pau-2.js" /* webpackChunkName: "component---src-pages-pau-2-js" */),
  "component---src-pages-san-urbano-js": () => import("./../../../src/pages/san-urbano.js" /* webpackChunkName: "component---src-pages-san-urbano-js" */)
}

